import React from 'react';

import frontImage from './images/page1_secondtry_edit_2.png';
import './App.css';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AllInfo from './allInfo';
import AllInfoEnglish from './allInfoEnglish';
import ReceptionOnly from './receptionOnly';
import ReceptionOnlyEnglish from './receptionOnlyEnglish';

class LoginForm extends React.Component {

	constructor() {
		super();
		this.state = {
			code: "",
			codeFound: false,
			validCode: false,
			validated: false,
			english: false,
		};
	}

	login(event) {
		const form = event.currentTarget;
		var validated = form.checkValidity();
		event.preventDefault();
  	event.stopPropagation();
		this.setState({ validated: validated });

		var code = this.refs.codeInput.value;
		code = code.toUpperCase().trim();
		if (code === "BLBVM") {
			this.props.showInfo(false, this.state.english);
		}
		else if (code === "BVBSH") {
			this.props.showInfo(true, this.state.english);
		}
		else {
			console.log("NAY");
			this.setState({
				codeFound: true,
				validCode: false,
			});
		}
	}

	isValidCode(code) {
		code = code.toUpperCase().trim();
		if (code === "BLBVM") {
			return true;
		}
		else if (code === "BVBSH") {
			return true;
		}
		else {
			return false;
		}
	}

	changeCode(event) {
		var newCode = this.refs.codeInput.value;
		var validCode = this.isValidCode(newCode);
		this.setState({code: this.refs.codeInput.value, codeFound: true, validCode: validCode});

	}

	getCode() {
		var code = this.state.code;
		code = code.toUpperCase().trim();
		return code;
	}

	setToEnglish(english) {
		this.setState({
			english: english,
		});
	}

	render() {
		const { validated } = this.state;
		return (
			<div>
      <h1>Karel & Ilse</h1>
			<h3>31 augustus 2019</h3>
			<img src={frontImage} alt="Karel & Ilse" className="front-image"></img>
			<div className="login-form-container">
			<p><a href="#" onClick={() => this.setToEnglish(!this.state.english)}>{this.state.english ? "Dutch Version" : "English Version"}</a></p>
			{
				this.state.english
				? "Please enter the code that can be found on the wedding invitation:"
				: "Geef hier de code in die je terug kunt vinden op je uitnodiging:"
			}
				
				<div className="login-form">
				<Form onSubmit={e => this.login()} validated={false}>
					<Form.Group controlId="formCode">
						<Form.Control
							ref="codeInput"
							type="text"
							placeholder="Enter code"
							value={this.state.code}
							onChange={(e) => this.changeCode(e)}
							style={{width: 105, marginLeft: 'auto', marginRight: 'auto'}}
							isInvalid={this.state.codeFound && !this.state.validCode}
							/>
						<Form.Control.Feedback ref="bla" type="invalid">
							Gelieve een geldige code in te vullen.
						</Form.Control.Feedback>
					</Form.Group>
					<Button variant="danger" type="submit" onClick={e => this.login(e)}>Login</Button>
					</Form>
					</div>
				</div>
			</div>
		);
	}
}

class Menu extends React.Component {

	constructor() {
		super();
		this.state = {
			loggedIn: false,
			showAll: false,
			english: false,
		};
	}

	showInfo(everything, english) {
		console.log("Show " + everything + " with english " + english);
		this.setState({
			loggedIn: true,
			showAll: everything,
			english: english,
		});
	}

	componentDidUpdate() {
		console.log("whurl");
		window.scrollTo(0, 0);
	}

  render() {
		if (!this.state.loggedIn) {
			return <LoginForm showInfo={(everything, english) => this.showInfo(everything, english)} />;
		}
		else if (this.state.showAll) {
			if (this.state.english) {
				return <AllInfoEnglish/>;
			}
			else {
				return <AllInfo />;
			}
		}
		else {
			if (this.state.english) {
				return <ReceptionOnlyEnglish />;
			}
			else {
				return <ReceptionOnly />;
			}
		}
  }
}

class App extends React.Component {

  render() {
		return (
			<div>
				<Menu />
			</div>
		);
  }
}

export default App;

import React from 'react';

import Heart from './images/favicon.jpg';
import Cats from './images/catspage3_edit.png';
import Boekweit from './images/boekweit.png';
import './App.css';


class AllInfo extends React.Component {

	constructor() {
		super();
		this.state = {
		};
	}
	
	render() {
		return (
			<div className="info">
    			<h1>Karel <img src={Heart} width="40px"/> Ilse</h1>
				<p>
					Wij geven elkaar het jawoord op 31 augustus, en we willen jou heel graag erbij hebben! Hieronder vind je alle info over onze grote dag. Als je daarna nog vragen hebt, stuur dan gerust een mailtje naar <a href="mailto:karelenilsetrouwen@gmail.com">KarelEnIlseTrouwen@gmail.com</a>.
				</p>

				<h3>Belangrijk</h3>

				<p>
					Eerst en vooral een paar belangrijke afspraken. Omdat we een zeer uitgebreide vriendenkring hebben, kunnen we helaas niet alle partners uitnodigen op ons feest. Daarom willen we je vriendelijk vragen om enkel een partner mee te nemen wanneer deze persoonlijk uitgenodigd is op de enveloppe van het kaartje. Zo kunnen wij zeker zijn dat er voldoende plaats is voor iedereen.
				</p>

				<p>
					We willen ook vragen om te vermelden of en hoeveel kinderen je meeneemt. Let op: er zijn geen voorzieningen of opvang voor kinderen voorzien! Zorg dus dat je alles zelf bijhebt.
				</p>

				<h3>Planning</h3>

				<div>
				<ul type="disc">
					<li>
					<p>
						<b>Huwelijksviering om 13:00</b><br/>
						<div className="sub">
							<a href="https://goo.gl/maps/8XsioSxU6LEUfnTf9" target="_blank">Heilige Drievuldigheidskerk<br/>
							Wapenstilstandlaan 57, 2600 Berchem</a>
						</div>
					</p>
					<p>
						Op wandelafstand van trein (Antwerpen-Berchem), tram en bus. Er is ook genoeg parkeergelegenheid in de buurt, maar het is wel (goedkoop) <a target="_blank" href="https://www.antwerpen.be/nl/info/5caf4b1eb8d62811d679d093/parkeren-in-de-roze-zone">betalend parkeren</a>.
					</p>
					</li>

					<li>
					<p>
						<b>Aansluitend receptie tot 16:30</b><br/>
						<div className="sub">
							<a href="https://goo.gl/maps/Vu56tUDybLT3ogu3A" target="_blank">Casa Karel & Ilse<br/>
							Berchemlei 217, 2140 Borgerhout</a>
						</div>
					</p>
					<p>
						De kerk bevindt zich vlak bij ons huis, dus na de viering trekken we met z'n allen te voet naar de receptie. Na de receptie gaan Karel en Ilse foto's trekken en kunnen de gasten iets gaan eten/drinken ter voorbereiding van het avondfeest.
					</p>
					</li>

					<li>
					<p>
						<b>Groot dansfeest van 20:00 tot 04:00</b><br/>
						<div className="sub">
							<a href="https://goo.gl/maps/UuEu7oiRzY1MDSDeA" target="_blank">Sint-Jobsesteenweg 64, 2930 Brasschaat</a>
						</div>
					</p>
					<p>
						Het dansfeest zal plaatsvinden in de overdekte graanschuur van een oude hoeve. Er is parkeergelegenheid op het domein, en er is ook de mogelijkheid om een tent op te zetten en te kamperen. Aangezien de schuur open is langs de zijkanten en het ook op een zomernacht fris kan worden, raden we aan om een warme trui mee te nemen voor de late uurtjes. Er is geen avondmaaltijd voorzien; het is dus de bedoeling dat jullie op voorhand gegeten hebben.<br/>Doe comfortabele dansschoenen aan!
					</p>
					</li>
				</ul>
				</div>

				<h3>Cadeautip</h3>

				<p>Cadeau's mogen ter plaatse in onze cadeaubus gestoken worden of gestort worden op BE78 9733 5577 2186.</p>

				<h3>Dresscode</h3>

				<p>Kies een outfit waarin/waarbij je je comfortabel voelt.</p>

				<h3>Aanwezigheid bevestigen</h3>
				<p>
					We willen graag vragen om je aanwezigheid te bevestigen vóór 31 juli. Je kunt dit doen met <a href="https://forms.gle/XgTtxZEfyCKJjtfD7" target="_blank">dit formulier</a>. Als je niet kan komen, laat dan even iets weten op <a href="mailto:karelenilsetrouwen@gmail.com">KarelEnIlseTrouwen@gmail.com</a>.
				</p>

				<div style={{textAlign: 'center'}}>
				<img src={Boekweit} style={{width: 50, verticalAlign: 'bottom'}}></img>
					<img src={Cats} style={{width: '70%', textAlign: 'center'}} alt="De Poezen"></img>
				</div>
				
			</div>
		);
	}
}


export default AllInfo;

import React from 'react';

import Heart from './images/favicon.jpg';
import Cats from './images/catspage3_edit.png';
import Boekweit from './images/boekweit.png';
import './App.css';


class ReceptionOnlyEnglish extends React.Component {

	constructor() {
		super();
		this.state = {
		};
	}
	
	render() {
		return (
			<div className="info">
    			<h1>Karel <img src={Heart} width="40px"/> Ilse</h1>
				<p>
					We are getting married on August 31st, and we would love to have you there! Below you can find all the important information about our big day. If you have any remaining questions, feel free to drop us an email at <a href="mailto:karelenilsetrouwen@gmail.com">KarelEnIlseTrouwen@gmail.com</a>.
				</p>

				<h3>Important information</h3>

				<p>
					A few important things first. Because we have a lot of friends, we unfortunately cannot invite all partners to our party. We kindly ask you to only take your partner with you if they were invited on the envelope of the wedding invitation. This way, we can ensure enough room for everybody.
				</p>

				<p>
					We would also like you to let us know if and how many children you will be bringing. There will be no daycare or other facilities for children, so make sure to bring everything they need with you.
				</p>

				<h3>Planning</h3>

				<div>
				<ul type="disc">
					<li>
					<p>
						<b>Wedding ceremony at 13:00</b><br/>
						<div className="sub">
							<a href="https://goo.gl/maps/8XsioSxU6LEUfnTf9" target="_blank">Heilige Drievuldigheidskerk<br/>
							Wapenstilstandlaan 57, 2600 Berchem</a>
						</div>
					</p>
					<p>
					Walking distance from the train station (Antwerpen-Berchem), trams and buses. There is plenty of (<a href="https://www.antwerpen.be/nl/info/5caf4b1eb8d62811d679d093/parkeren-in-de-roze-zone" target="_blank">paid</a>) parking space in the neighbourhood. Some general information about getting around in Antwerp can be found <a target="_blank" href="https://www.slimnaarantwerpen.be/en/car-taxi">here</a>.
					</p>
					</li>

					<li>
					<p>
						<b>Followed by reception until 16:30</b><br/>
						<div className="sub">
							<a href="https://goo.gl/maps/Vu56tUDybLT3ogu3A" target="_blank">Casa Karel & Ilse<br/>
							Berchemlei 217, 2140 Borgerhout</a>
						</div>
					</p>
					<p>
						The church is very close to our home, so after the ceremony we will walk to the reception together.
					</p>
					</li>
				</ul>
				</div>

				<h3>Wedding presents</h3>

				<p>Wedding presents can be put in our gift box or can be wired to our bank account: BE78 9733 5577 2186 (BIC ARSPBE22XXX).</p>

				<h3>Dresscode</h3>

				<p>Pick an outfit you feel comfortable in/with.</p>

				<h3>Confirm attendance</h3>
				<p>
					Please confirm your attendance before July 31st. You can confirm with <a href="https://forms.gle/ho7wpFKipG76yFgx8" target="_blank">this form</a>. If you can't come, please let us know at <a href="mailto:karelenilsetrouwen@gmail.com">KarelEnIlseTrouwen@gmail.com</a>.
				</p>

				<div style={{textAlign: 'center'}}>
				<img src={Boekweit} style={{width: 50, verticalAlign: 'bottom'}}></img>
					<img src={Cats} style={{width: '70%', textAlign: 'center'}} alt="De Poezen"></img>
				</div>
				
			</div>
		);
	}
}


export default ReceptionOnlyEnglish;
